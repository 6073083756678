@font-face {
    font-family: "Geometric";
    src: url("assets/fonts/geometric-regular.woff2") format("woff2");
    font-weight: 500;
}

@font-face {
    font-family: "Geometric";
    src: url("assets/fonts/geometric-medium.woff2") format("woff2");
    font-weight: 700;
}