.search-box {
    z-index: -1;
    @apply transition-opacity left-1/2 transform -translate-x-1/2 absolute antialiased;
    width: calc(100% - 22rem);
}

.search-box fieldset {
    @apply flex gap-x-6 xl:gap-x-10 w-full;
}

.search-box input {
    @apply outline-none h-12 px-3 border border-silver w-full appearance-none rounded-none;
}

.search-box ::placeholder {
    @apply text-gray-300;
}