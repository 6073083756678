.dashboard__table {
    @apply text-sm w-full dark:text-white;
}

.dashboard__table caption {
    @apply bg-white text-left px-6 md:px-10;
}

.table__tools {
    @apply bg-white  dark:bg-zinc-800 text-left px-6 md:px-10;
}

.dashboard__table caption select {
    @apply text-red text-sm antialiased inline-flex font-semibold items-center gap-x-1 outline-none
}

.dashboard__table thead {
    @apply bg-white dark:bg-zinc-800 dark:text-white sticky top-0 shadow-lg; 
}

.dashboard__table th,
.dashboard__table td {
    @apply py-4 px-6 md:px-10 text-left
}

.dashboard__table td {
    @apply border-t border-neutral-200 dark:border-zinc-600 truncate
}

.dashboard__table tbody th {
    @apply font-normal border-t border-neutral-200 dark:border-zinc-600
}

.dashboard__table tbody a {
    @apply text-red truncate;
}