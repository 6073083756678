

.form-field--reveal {
    @apply block justify-start items-start w-full;
}

.form-field--reveal > .reveal-more {
    @apply w-full flex flex-col gap-y-4;
}

.reveal-more .select {
    max-width: 390px;
}

.form-field--reveal > button {
    @apply mt-4 appearance-none outline-none flex items-center gap-x-1;
}

.form-field--reveal > button svg {
    @apply w-4 h-auto text-red;
}

.reveal-more .dialog__toggle {
    @apply text-xs text-black font-semibold
}

.reveal-more > li { 
    @apply hidden flex-col gap-y-2;
}

.reveal-more[data-depth="1"] > li:first-child,
.reveal-more[data-depth="2"] > li:nth-child(-n+2),
.reveal-more[data-depth="3"] > li:nth-child(-n+3),
.reveal-more[data-depth="4"] > li:nth-child(-n+4),
.reveal-more[data-depth="5"] > li:nth-child(-n+5),
.reveal-more[data-depth="6"] > li:nth-child(-n+6),
.reveal-more[data-depth="7"] > li:nth-child(-n+7) {
    @apply flex;
}

.reveal-more textarea {
    @apply w-full border appearance-none bg-white p-2 outline-none resize-none order-2  border-neutral-300 rounded-sm dark:text-black;
}

.reveal-more textarea[disabled] {
    @apply opacity-40
}