.combo-group {
    @apply border border-neutral-300 block w-full clear-both col-start-1 col-span-12 flex bg-white;
}

.combo-group > aside {
    @apply divide-y w-1/6 divide-neutral-300 bg-silver
}

.combo-group > aside button {
    @apply w-full text-left p-3 bg-neutral-100 appearance-none border-r border-neutral-300 text-black;
}

.combo-group > aside.last > button:last-child {
    border-bottom: 1px rgb(212 212 212 / var(--tw-divide-opacity)) solid;
}

.combo-group > aside button:disabled {
    @apply bg-white border-r border-transparent;
    border-right: 1px transparent solid !important;
}

.combo-group__matter {
    @apply hidden w-full
}

.combo-group__matter[aria-visible="true"] {
    @apply block
}

.combo-group__matter textarea {
    @apply border border-neutral-300 w-full h-24 p-2 rounded mt-3 outline-none appearance-none resize-none dark:text-black
}
