.log-in {
    @apply w-full max-w-md text-black antialiased mx-auto;
}

.log-in legend {
    @apply text-center text-white text-xl md:text-2xl mb-4;
}

.loading {
    @apply text-red flex items-center gap-x-4;
}

.loading + .log-in {
    @apply hidden;
}