@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html, body {
      font-family: 'Geometric', system-ui, sans-serif;
      background-color: var(--silver);
    }
}

:root {
  --white: #ffffff;
  --primary: #009ff9;
  --black: #2d373b;
  --red: #f2625f;
  --grey: #5e5e5e;
  --silver: #eeeeee;
}