
.swiper-wrapper {
    @apply order-2 flex-1;
}

.swiper-links > button {
    @apply relative dark:text-white transition-colors;
}

.swiper-links > button::after {
    content: "";
    @apply h-1 bg-neutral-300 dark:bg-zinc-500 absolute w-full bottom-0 left-0 transition-colors;
}

.swiper-links > button:disabled {
    @apply text-red;
}

.swiper-links > button:disabled::after {
    @apply bg-red;
}

.scenes {
    @apply relative
}

.scene {
    @apply h-full flex flex-col col-start-1 row-start-1 opacity-0 transition-opacity px-6 md:px-10 pt-6 pb-24 absolute top-0 overflow-auto;
    z-index: -10;
    max-height: 0;
}

.scene.is-active {
    @apply z-10 opacity-100 relative;
    max-height: 100%;
    /*max-height: calc(100vh - 196px);*/
}

.alert {
    @apply bg-red text-white text-xs flex flex-wrap gap-x-3 items-center py-2 px-4 mb-4 antialiased;
}

.alert svg {
    @apply w-4 h-auto align-top;
}