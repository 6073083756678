.order__header {
    @apply md:h-36 w-full px-6 md:px-10 bg-white dark:bg-zinc-800 flex flex-wrap items-start pt-4 justify-between relative gap-x-10;
}

.order__header-back {
    @apply text-red h-10 text-base antialiased inline-flex items-center;
}

.order__email-client {
    @apply text-red h-10 text-base antialiased inline-flex items-center order-2 md:order-3 ml-auto;
}

.order__header-title {
    @apply w-full md:w-auto order-2 antialiased dark:text-white text-center leading-none text-black md:absolute md:left-1/2 transform md:-translate-x-1/2;
}

.order__header-title a {
    @apply text-red underline underline-offset-2;
}