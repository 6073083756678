

.button--large {
    @apply w-full font-bold bg-red rounded py-4 uppercase px-6 leading-none text-white text-xl active:opacity-70;
    box-shadow: 2px 2px 0px #c6504d;
}


.button--large:disabled {
    @apply text-neutral-400 bg-neutral-600;
    box-shadow: 2px 2px 0px #777777;
}