.form-field--image-set {
    @apply flex flex-col gap-y-6;
}

.form-field--image-set ul {
    @apply grid grid-cols-6 md:grid-cols-12 gap-2 md:gap-8 order-2 w-full mb-8;
}

.form-field--image-set ul > li {
    @apply col-span-2;
}

.form-field--image-set button,
.form-field--image-set .placeholder {
    @apply outline-none h-full w-full appearance-none bg-white dark:bg-zinc-800 border dark:border-zinc-400 flex items-center justify-center aspect-square relative  border-neutral-300;
}

.form-field--image-set button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.form-field--image-set button svg,
.form-field--image-set .placeholder svg {
    @apply w-16 h-auto text-red dark:text-zinc-200;
}

.form-field--image-set button svg {
    @apply absolute w-10 bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full;
}