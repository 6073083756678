.menu:has(.menu__dropdown) {
    @apply relative z-50;
}

.menu__field {
    @apply flex items-center;
}

button.menu__trigger {
    @apply bg-white rounded border border-neutral-300 appearance-none flex flex-1 p-3 outline-none whitespace-nowrap truncate relative w-full
}

button.menu__trigger span {
    @apply whitespace-nowrap truncate overflow-hidden pr-8 block
}

button.menu__trigger svg {
    @apply absolute right-3 top-1/2 transform -translate-y-1/2 opacity-30
}

button.menu__clear {
    @apply text-red w-8 h-full flex items-center justify-center appearance-none p-1
}

button.menu__clear svg {
    @apply w-full h-full text-red
}

.menu__dropdown {
    @apply absolute left-0 bg-white border border-neutral-300 rounded w-full mt-1;
    max-height: 300px;
    overflow: auto;
}

.menu__dropdown ul {
    @apply flex-col
}

.menu__dropdown button {
    @apply  appearance-none p-2 w-full text-left outline-none
}
.menu__dropdown button:disabled {
    @apply bg-red text-white
}