.scene__form {
    @apply text-xs antialiased grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2 md:gap-x-10;
}

.scene__form h2 {
    @apply text-sm font-bold text-center uppercase md:col-span-2 md:col-start-1;
}

.scene__form label,
.scene__form h3 {
    @apply uppercase font-normal;
}

.scene__form fieldset {
    @apply grid grid-cols-12 gap-x-4 gap-y-3 items-start;
}

.scene__form legend {
    @apply text-xs font-bold uppercase col-span-2 float-left w-full;
}

.form-field {
    @apply flex items-center gap-x-4;
}

.form-field label,
.form-field h3 {
    @apply order-1 md:w-64;
}

.form-field--select .select {
    order: 2;
    min-width: 290px;
}

/*
.form-field select {
    @apply bg-transparent font-semibold;
    border: 1px black solid;
}
*/
.form-field input {
    @apply order-2 p-2 bg-white border border-neutral-300 rounded-sm outline-none text-black;
    flex: 2;
}
/*
.form-field > .select {
    flex: 2;
    @apply order-2;
}
.select {
    @apply relative;
}

.select select {
    @apply pl-5 py-2
}

.select svg {
    @apply absolute left-0 top-1/2 transform -translate-y-1/2 text-red w-4 h-4;
}
*/
.form-field input[type="number"] {
    @apply appearance-none;
}

.form-field input:read-only {
    @apply bg-transparent font-bold p-0 border-0 outline-none dark:text-white;
}

.form-field--date .input {
    @apply order-2 relative;
    flex: 2;
}

.form-field--date .input input {
    @apply pl-8;
}

.form-field--date .input input:not[disabled] {
    @apply py-3;
}

.form-field--date .input.disabled {
    @apply pl-0;
}

.form-field--date .input svg {
    @apply text-red absolute left-0 top-1/2 transform -translate-y-1/2 w-4 h-auto ml-2;
}

.form-field--date .input.disabled svg {
    @apply text-red;
}

.form-field--week ul {
    @apply flex gap-x-2 items-center;
}

.form-field--week ul li {
    @apply w-10 h-10 bg-white rounded-sm border block overflow-hidden flex flex-col justify-between p-1;
}

.form-field--week label {
    @apply text-gray-300 font-semibold order-1 leading-none w-auto;
}

.form-field--week input {
    @apply appearance-none p-0 outline-none font-bold border-0;
}

.form-field--textarea {
    @apply flex-col gap-y-1;
}

.form-field--textarea label {
    @apply order-1 w-full;
}

.form-field--textarea textarea {
    @apply w-full border appearance-none bg-white p-2 h-32 outline-none resize-none order-2 dark:text-black  border-neutral-300 rounded-sm;
}

.form-field--textarea > button {
    @apply order-3 text-red mt-2 font-normal outline-none;
}

.form-field--textarea dialog {
    @apply top-1/2 left-1/2 bg-white dark:bg-zinc-800 rounded w-full shadow-xl transform -translate-x-1/2 -translate-y-1/2 dark:text-black;
    max-width: 50vw;
}

.form-field--textarea dialog textarea {
    min-height: 38vh;
    @apply dark:bg-zinc-800 dark:text-white;
}

dialog::backdrop {
    @apply bg-black;
}

.form-field--radios {
    @apply items-start;
}

.form-field--radios ul {
    @apply order-2 flex items-center flex-wrap gap-x-2;
}

@media (min-width: 768px) {
    .form-field--radios ul {
        flex: 2;
    }
}

.form-field--radios li {
    @apply flex gap-x-1;
    flex-flow: row-reverse;
}

.form-field--radios label {
    @apply w-auto;
}

input[type="radio"] {
    @apply accent-red;
}

.calendar {
    @apply flex gap-x-2;
}

.calendar svg {
    @apply w-4 h-auto align-top text-red;
}


.form-field--combo ul {
    @apply flex order-2 w-full gap-x-3  bg-transparent;
}

.form-field--combo li {
    @apply bg-transparent  flex items-center;
}

.form-field--combo li label {
    flex: none;
    @apply w-auto text-neutral-500 bg-transparent dark:text-neutral-300;
}

.form-field--combo h3 {
    @apply order-1 whitespace-nowrap block md:w-40;
}

@media (min-width: 768px) {
    .form-field--combo h3 {
        min-width: 10rem;
    }
}

.form-field--combo select {
    @apply bg-red text-white px-4 py-3 leading-none appearance-none text-center w-14;
}

.form-field--combo label {
    @apply bg-white px-4 py-3 text-neutral-400;
}

.form-field--accountancy input:read-only {
    @apply text-right;
}

.form-field--flags {
    @apply mt-10 w-full col-span-12 lg:w-96 mx-auto;
}

.form-field--flags {
    @apply flex items-start justify-center
}

.form-field--flags > li {
    @apply flex-1 text-center;
}

.form-field--flags input:not([checked]) {
    @apply opacity-80
}

.sr-only {
    @apply hidden
}

.form-field--alternate {
    @apply md:col-span-8 md:col-start-4
}

.form-field--alternate label,
.form-field--alternate h3 {
    @apply text-right
}

.form-field--alternate > input,
.form-field--alternate > .select,
.form-field--alternate ul {
    @apply flex-none;
    min-width: 16rem;
}

.fim__control, .fim__menu {
    @apply text-black;
}
