.dialog {
    @apply relative inline-block self-start;
}

.dialog__popup {
    @apply absolute border bg-white rounded-lg overflow-hidden z-10 antialiased top-0 left-full transform -translate-y-4 translate-x-4;
    box-shadow: 0 0 2em rgba(0,0,0,.4);
}

.dialog__popup header {
    @apply bg-black text-white flex py-3 px-2 text-sm w-64;
    max-height: 400px;
}

.dialog__popup header button {
    @apply text-red font-semibold;
}

.dialog__popup ul {
    @apply divide-y divide-neutral-100 text-sm
}

.dialog__popup ul input:checked {
    @apply accent-red
}

.dialog__popup ul label {
    @apply flex w-full py-2 px-2 flex items-center gap-x-2
}

.dialog__popup ul input:checked + span {
    @apply text-red transition-colors
}


.dialog__toggle {
    @apply text-red text-sm flex items-center gap-x-1 font-semibold antialiased outline-none;
}
