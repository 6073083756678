.photos {
    @apply col-start-1 col-span-12 border bg-white border-neutral-300 overflow-hidden p-2 rounded text-black;

}



.photos__set {
    @apply grid grid-cols-6 gap-3 mt-3
}

.photos__set img {
    @apply align-top w-full h-auto rounded-lg overflow-hidden;
}

button.photos__remove {
    @apply text-red mt-2 block text-center appearance-none flex justify-center w-full;
}